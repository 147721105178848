import * as React from "react"

import Card from "../card"

const Project = () => {
    return(
        <div className="project" id="project">
            <h1>Projects</h1>
            <p>This is the are where I'm going to link to some projects that I have done.
            </p>
            <Card />
        </div>
    )
}

export default Project